body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #393939;
}

h1 {

}

.App .MuiAppBar-root .DaySelector {
	flex-grow: 1;
	text-align: center;
}
.App .MuiAppBar-root .DaySelector .MuiInputBase-root {
	color: white;
	padding: 5px 0 0 0;
}

.App .MuiAppBar-root .DaySelector .MuiInput-underline:before {
	border-bottom-color: white;
}

.App .MuiAppBar-root .DaySelector button {
	margin-left: 20px;
}

.MuiButton-label a,
.MuiButton-label a:visited,
.MuiButton-label a:active,
.MuiButton-label a:hover {
    color: white;
    text-decoration: none;
}

.App .centred {
	display: flex;
	width: 100%;
	height: 95vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.align-middle {
	text-align: center;
}

.Signin .field {
	margin: 0 0 1em 0;
}

.Day .field, .StaticApp .field, .CruiseShipApp .field {
	margin: 30px 0;
}
.StaticApp .field label {
    text-transform: capitalize;
}

.FormattedStringList .field,
.SailingList .field {
	margin: 0 0 5px 0;
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
}

.FormattedStringList .field .FormattedStringInput,
.SailingList .field .FormattedStringInput {
	flex: 1 1 100%;
	margin-right: 10px;
}
.SailingList .field > * {
	flex: 1 0 15%;
}
.FormattedStringList .field > .MuiIconButton-root,
.SailingList .field > .MuiIconButton-root {
	flex: 1 0 auto;
}
.SailingList .field {
    flex-wrap: wrap;
}
.SailingList .field .MuiTextField-root:last-child {
    flex: 1 0 calc(100% - 65px);
    margin-right: 65px;
}

.FormattedStringList .field button,
.SailingList .field button {
	margin-top: 0;
	margin-left: 5px;
}
.FormattedStringList button,
.SailingList button {
	margin-top: 20px;
}

.saveButtons {
	text-align: center;
}

.saveButtons .MuiButton-root {
	margin: 0 10px;
}
